export const VALIDATE_TESTLINE = "VALIDATE_TESTLINE";
export const VALIDATE_TESTLINE_SUCCESS = "VALIDATE_TESTLINE_SUCCESS";
export const VALIDATE_TESTLINE_FAILURE = "VALIDATE_TESTLINE_FAILURE";

export const VALIDATE_M3U_TESTLINE = "VALIDATE_M3U_TESTLINE";
export const VALIDATE_M3U_TESTLINE_SUCCESS = "VALIDATE_M3U_TESTLINE_SUCCESS";
export const VALIDATE_M3U_TESTLINE_FAILURE = "VALIDATE_M3U_TESTLINE_FAILURE";




