export const GET_MOVIES_CHANNELS = "GET_MOVIES_CHANNELS";
export const GET_MOVIES_CHANNELS_SUCCESS = "GET_MOVIES_CHANNELS_SUCCESS";
export const GET_MOVIES_CHANNELS_FAILURE = "GET_MOVIES_CHANNELS_FAILURE";

export const GET_MOVIE_INFO = "GET_MOVIE_INFO";
export const GET_MOVIE_INFO_SUCCESS = "GET_MOVIE_INFO_SUCCESS";
export const GET_MOVIE_INFO_FAILURE = "GET_MOVIE_INFO_FAILURE";

export const SET_MOVIE_FAVOURITES = 'SET_MOVIE_FAVOURITES';
export const REMOVE_MOVIE_FAVOURITES = 'REMOVE_MOVIE_FAVOURITES'

export const ADD_RECENT_PLAYED = 'ADD_RECENT_PLAYED';