export const GET_SERIES_CHANNELS = "GET_SERIES_CHANNELS";
export const GET_SERIES_CHANNELS_SUCCESS = "GET_SERIES_CHANNELS_SUCCESS";
export const GET_SERIES_CHANNELS_FAILURE = "GET_SERIES_CHANNELS_FAILURE";

export const GET_SERIES_INFO = "GET_SERIES_INFO";
export const GET_SERIES_INFO_SUCCESS = "GET_SERIES_INFO_SUCCESS";
export const GET_SERIES_INFO_FAILURE = "GET_SERIES_INFO_FAILURE";

export const SET_SERIES_FAVOURITES = 'SET_SERIES_FAVOURITES';
export const REMOVE_SERIES_FAVOURITES = 'REMOVE_SERIES_FAVOURITES';

export const ADD_RECENT_PLAYED = 'ADD_RECENT_PLAYED';
