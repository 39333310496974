export const GET_LIVE_CHANNELS = "GET_LIVE_CHANNELS";
export const GET_LIVE_CHANNELS_SUCCESS = "GET_LIVE_CHANNELS_SUCCESS";
export const GET_LIVE_CHANNELS_FAILURE = "GET_LIVE_CHANNELS_FAILURE";

export const GET_LIVE_EPG = "GET_LIVE_EPG";
export const GET_LIVE_EPG_SUCCESS = "GET_LIVE_EPG_SUCCESS";
export const GET_LIVE_EPG_FAILURE = "GET_LIVE_EPG_FAILURE";

export const SET_LIVE_FAVOURITES = 'SET_LIVE_FAVOURITES';
export const REMOVE_LIVE_FAVOURITES = 'REMOVE_LIVE_FAVOURITES'

export const ADD_LOCK_CATEGORY = 'ADD_LOCK_CATEGORY';

export const ADD_RECENT_PLAYED = 'ADD_RECENT_PLAYED';
